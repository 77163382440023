import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxContentLoadingModule } from "ngx-content-loading";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { HomeComponent } from "./components/home/home.component";
import { ReactiveFormsModule } from "@angular/forms";
import {
  MatCardModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatIconModule,
  MatToolbarModule,
  MatDividerModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldControl,
  MAT_DATE_LOCALE,
  MatSlideToggleModule,
  MatListModule,
  MatExpansionModule,
  MatSidenavModule,
  MatBadgeModule,
  MatTooltipModule,
  MatDivider,
} from "@angular/material";
import { AppRoutingModule } from "./app-routing.module";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { NgxHmCarouselModule } from "ngx-hm-carousel";
import { PopularcategoriesComponent } from "./components/popularcategories/popularcategories.component";
import { AdvertisingComponent } from "./components/advertising/advertising.component";
import { PopularandoffersComponent } from "./components/popularandoffers/popularandoffers.component";
import { OfferpageComponent } from "./components/offerpage/offerpage.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MallOffersComponent } from "./components/mall-offers/mall-offers.component";
import { OwlModule } from "ngx-owl-carousel";
import { SignupDialogComponent } from "./components/signup-dialog/signup-dialog.component";
import { JoinUsOnHomeComponent } from "./components/join-us-on-home/join-us-on-home.component";

import { Ng5SliderModule } from "ng5-slider";
import { AboutComponent } from "./components/about/about.component";
import { LocationfilterComponent } from "./components/locationfilter/locationfilter.component";
import { AboutListingComponent } from "./components/about-listing/about-listing.component";
import { AboutBodyComponent } from "./components/about-body/about-body.component";
import { AboutBodyContentComponent } from "./components/about-body-content/about-body-content.component";
import { ContactComponent } from "./components/contact/contact.component";
import { ContactmapsComponent } from "./components/contactmaps/contactmaps.component";
import { ContactformComponent } from "./components/contactform/contactform.component";
import { ContactbodyComponent } from "./components/contactbody/contactbody.component";
import { AgmCoreModule } from "@agm/core";
import { FranchiseComponent } from "./components/franchise/franchise.component";
import { FranchisebodyComponent } from "./components/franchisebody/franchisebody.component";
import { FranchisecontentComponent } from "./components/franchisecontent/franchisecontent.component";
import { FranchiselistingComponent } from "./components/franchiselisting/franchiselisting.component";
import { RegisterComponent } from "./components/register/register.component";
import { RegistercontentComponent } from "./components/registercontent/registercontent.component";
import { RegisterbodyComponent } from "./components/registerbody/registerbody.component";
import { RegisterlistingComponent } from "./components/registerlisting/registerlisting.component";
import { ReviewComponent } from "./components/review/review.component";
import { ReviewcontentComponent } from "./components/reviewcontent/reviewcontent.component";
import { ReviewbodyComponent } from "./components/reviewbody/reviewbody.component";
import { ReviewlistingComponent } from "./components/reviewlisting/reviewlisting.component";
import { ShopdetailsComponent } from "./components/shopdetails/shopdetails.component";
import { ShopdetailsbodyComponent } from "./components/shopdetailsbody/shopdetailsbody.component";
import { ShopdetailscontentComponent } from "./components/shopdetailscontent/shopdetailscontent.component";
import { ShopdetailslistingComponent } from "./components/shopdetailslisting/shopdetailslisting.component";
import { OffersComponent } from "./components/offers/offers.component";
import { OffersbodyComponent } from "./components/offersbody/offersbody.component";
import { OfferscontentComponent } from "./components/offerscontent/offerscontent.component";
import { OfferslistingComponent } from "./components/offerslisting/offerslisting.component";
import { MallDetailsWihtOutRelatedComponent } from "./components/mall-details-wiht-out-related/mall-details-wiht-out-related.component";
import { MallDetailsWihtOutRelatedbodyComponent } from "./components/mall-details-wiht-out-relatedbody/mall-details-wiht-out-relatedbody.component";
import { MallDetailsWihtOutRelatedcontentComponent } from "./components/mall-details-wiht-out-relatedcontent/mall-details-wiht-out-relatedcontent.component";
import { AddlistingComponent } from "./components/addlisting/addlisting.component";
import { TrendyOffersComponent } from "./components/trendy-offers/trendy-offers.component";
import { MalloffersListComponent } from "./components/malloffers-list/malloffers-list.component";
import { MalloffersListPageComponent } from "./components/malloffers-list-page/malloffers-list-page.component";
import { TrendyoffersDetailsListComponent } from "./components/trendyoffers-details-list/trendyoffers-details-list.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { PopularcatService } from "./components/popularcategories/popularcategories.service";
import { CarouselService } from "./components/carousel/carousel.service";
import { malloffersservice } from "./components/mall-offers/mall-offers.service";
import { offersservice } from "./components/offerpage/offerpage.service";
import { reviewcontent } from "./components/reviewcontent/reviewcontent.service";
import { franchisecontentService } from "./components/franchisecontent/franchisecontent.service";
import { selectlocationService } from "./components/select-location/select-location.service";
import { addlistingServiceH } from "./components/addlisting/addlistingH.service";
import { careerService } from "./components/career/career.service";
import { popularrandService } from "./components/popularandoffers/popularandoffers.service";
import { advertising } from "./components/advertising/advertising.service";
import { offerscontent } from "./components/offerscontent/offerscontent.service";
import { RatingModule } from "ng-starrating";
import { joinusonhome } from "./components/join-us-on-home/join-us-on-home.service";
import { shopdetailscontentService } from "./components/shopdetailscontent/shopdetailscontent.service";
import { mallofferslistpageService } from "./components/malloffers-list-page/malloffers-list-page.service";
import { malldetailswihtoutrelatedcontentService } from "./components/mall-details-wiht-out-relatedcontent/mall-details-wiht-out-relatedcontent.service";
import { headerService } from "./components/header/header.service";
import { contactform } from "./components/contactform/contactform.service";
import { Resolver } from "./components/resolver";
import { masterService } from "./components/master.service";
import { GlobalService } from "./components/global.service";
import { DataService } from "./components/data.service";
import { EventEmitterService } from "./components/event-emitter.service";
import { FormsModule } from "@angular/forms";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { ErrorComponent } from "./components/error/error.component";
import { CareerComponent } from "./components/career/career.component";
import { SelectLocationComponent } from "./components/select-location/select-location.component";
import { StorageServiceModule } from "ngx-webstorage-service";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { TermsOfUseComponent } from "./components/terms-of-use/terms-of-use.component";
import { DisclaimerComponent } from "./components/disclaimer/disclaimer.component";
import { InterceptorService } from "./components/interceptor.service";
// import { httpInterceptorProviders } from './components/http-interceptors/index';
// import { Cache } from './components/cache-service/cache';
// import { CacheMapService } from './components/cache-service/cache-map.service';
// import { ShareButtonModule,ShareButton } from '@ngx-share/button';
// import { ShareModule } from '@ngx-share/core';
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { VideoAdsComponent } from "./components/video-ads/video-ads.component";
import { BlogComponent } from "./components/blog/blog.component";
import { BlogListComponent } from "./components/blog/blog-list/blog-list.component";
import { FullBlogComponent } from "./components/blog/full-blog/full-blog.component";
import { CommentComponent } from "./components/blog/comment/comment.component";
import { ImageSliderComponent } from "./components/image-slider/image-slider.component";
import { CommonModule } from "@angular/common";
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AuthGuard } from "./auth/auth.guard";
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ChatBoxComponent } from './components/chat-box/chat-box.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CarouselComponent,
    HomeComponent,
    PopularcategoriesComponent,
    AdvertisingComponent,
    PopularandoffersComponent,
    OfferpageComponent,
    FooterComponent,
    MallOffersComponent,
    SignupDialogComponent,
    JoinUsOnHomeComponent,
    AboutComponent,
    LocationfilterComponent,
    AboutListingComponent,
    AboutBodyComponent,
    AboutBodyContentComponent,
    ContactComponent,
    ContactmapsComponent,
    ContactformComponent,
    ContactbodyComponent,
    FranchiseComponent,
    FranchisebodyComponent,
    FranchisecontentComponent,
    FranchiselistingComponent,
    RegisterComponent,
    RegistercontentComponent,
    RegisterbodyComponent,
    RegisterlistingComponent,
    ReviewComponent,
    ReviewcontentComponent,
    ReviewbodyComponent,
    ReviewlistingComponent,
    ShopdetailsComponent,
    ShopdetailsbodyComponent,
    ShopdetailscontentComponent,
    ShopdetailslistingComponent,
    OffersComponent,
    OffersbodyComponent,
    OfferscontentComponent,
    OfferslistingComponent,
    MallDetailsWihtOutRelatedComponent,
    MallDetailsWihtOutRelatedbodyComponent,
    MallDetailsWihtOutRelatedcontentComponent,
    AddlistingComponent,
    TrendyOffersComponent,
    MalloffersListComponent,
    MalloffersListPageComponent,
    TrendyoffersDetailsListComponent,
    ErrorComponent,
    CareerComponent,
    SelectLocationComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    DisclaimerComponent,
    PageNotFoundComponent,
    VideoAdsComponent,
    BlogComponent,
    BlogListComponent,
    FullBlogComponent,
    CommentComponent,
    ImageSliderComponent,
    LoginComponent,
    ProfileComponent,
    EditProfileComponent,
    ResetPasswordComponent,
    ForgetPasswordComponent,
    ChatBoxComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    Ng5SliderModule,
    AngularFontAwesomeModule,
    BrowserAnimationsModule,
    NgxContentLoadingModule,
    NoopAnimationsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    RatingModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatListModule,
    MatExpansionModule,
    MatSidenavModule,
    MatBadgeModule,
    AppRoutingModule,
    NgxHmCarouselModule,
    OwlModule,
    HttpClientModule,
    AutocompleteLibModule,
    InfiniteScrollModule,
    MatTooltipModule,
    StorageServiceModule,
    ReactiveFormsModule,
    // ShareButtonModule,
    // ShareModule,

    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAhAZI1a2yA-r7HHVTko9yWIEOv4M3iS2M",
    }),
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    PopularcatService,
    CarouselService,
    malloffersservice,
    offersservice,
    advertising,
    offerscontent,
    joinusonhome,
    shopdetailscontentService,
    mallofferslistpageService,
    malldetailswihtoutrelatedcontentService,
    headerService,
    Resolver,
    masterService,
    GlobalService,
    EventEmitterService,
    DataService,
    contactform,
    reviewcontent,
    franchisecontentService,
    selectlocationService,
    addlistingServiceH,
    careerService,
    popularrandService,
  ],
  // ,ShareButton
  bootstrap: [AppComponent],
  entryComponents: [SignupDialogComponent, LoginComponent, EditProfileComponent,
    ResetPasswordComponent,
    ForgetPasswordComponent],
})
export class AppModule { }
